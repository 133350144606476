<template>
  <section class="container my-20 grid items-center gap-10 md:my-32 lg:grid-cols-2">
    <article class="flex size-full max-w-xl flex-col gap-y-5 md:gap-y-5 md:p-12">
      <h2>Say BTTR. Ontdek wat jouw data te zeggen heeft.</h2>
      <p class="grow text-gray-700">
        Ontdek hoe Say BTTR jouw data inzichtelijk maakt en start met datagedreven keuzes voor de groei van je organisatie.
      </p>
      <UiButton
        class="mt-auto"
        to="chat"
        label="Probeer de tool"
        icon-position="left"
      />
    </article>
    <VideosStoryblok
      source="https://a.storyblok.com/f/275966/x/2f4a3bd99e/saybttr-launch.mp4"
      label="Praten met je data"
    />
  </section>
</template>
